import React from "react"
import Layout from "../../components/layout/Layout"
import "./index.css"

const InstagramIcon = () => (
  <svg viewBox="0 0 20 20" width="1rem" height="1rem" fill="currentcolor">
    <path d="M17 1h-14c-1.1 0-2 0.9-2 2v14c0 1.101 0.9 2 2 2h14c1.1 0 2-0.899 2-2v-14c0-1.1-0.9-2-2-2zM9.984 15.523c3.059 0 5.538-2.481 5.538-5.539 0-0.338-0.043-0.664-0.103-0.984h1.581v7.216c0 0.382-0.31 0.69-0.693 0.69h-12.614c-0.383 0-0.693-0.308-0.693-0.69v-7.216h1.549c-0.061 0.32-0.104 0.646-0.104 0.984 0 3.059 2.481 5.539 5.539 5.539zM6.523 9.984c0-1.912 1.55-3.461 3.462-3.461s3.462 1.549 3.462 3.461-1.551 3.462-3.462 3.462c-1.913 0-3.462-1.55-3.462-3.462zM16.307 6h-1.615c-0.382 0-0.692-0.312-0.692-0.692v-1.617c0-0.382 0.31-0.691 0.691-0.691h1.615c0.384 0 0.694 0.309 0.694 0.691v1.616c0 0.381-0.31 0.693-0.693 0.693z"></path>
  </svg>
)

const EmailIcon = () => (
  <svg viewBox="0 0 20 20" height="1rem" width="1rem" fill="currentcolor">
    <path d="M14.608 12.172c0 0.84 0.239 1.175 0.864 1.175 1.393 0 2.28-1.775 2.28-4.727 0-4.512-3.288-6.672-7.393-6.672-4.223 0-8.064 2.832-8.064 8.184 0 5.112 3.36 7.896 8.52 7.896 1.752 0 2.928-0.192 4.727-0.792l0.386 1.607c-1.776 0.577-3.674 0.744-5.137 0.744-6.768 0-10.393-3.72-10.393-9.456 0-5.784 4.201-9.72 9.985-9.72 6.024 0 9.215 3.6 9.215 8.016 0 3.744-1.175 6.6-4.871 6.6-1.681 0-2.784-0.672-2.928-2.161-0.432 1.656-1.584 2.161-3.145 2.161-2.088 0-3.84-1.609-3.84-4.848 0-3.264 1.537-5.28 4.297-5.28 1.464 0 2.376 0.576 2.782 1.488l0.697-1.272h2.016v7.057h0.002zM11.657 9.004c0-1.319-0.985-1.872-1.801-1.872-0.888 0-1.871 0.719-1.871 2.832 0 1.68 0.744 2.616 1.871 2.616 0.792 0 1.801-0.504 1.801-1.896v-1.68z"></path>
  </svg>
)

export default function About() {
  return (
    <Layout title="About">
      <div className="about-container">
        <div className="about-text-container">
          <img
            src="/images/profile.jpeg"
            className="about-image"
            alt="profile"
          />
          <p className="about-text">
            Nikhil Mishra holds a bachelor's degree in Mechanical Engineering
            and worked for about three and a half years at a power plant in
            Nagpur. An urge to explore himself as a person brought him to Delhi
            in order to pursue a diploma course in photography from Sri
            Aurobindo Centre for Arts and Communications. Since then he has been
            pursuing his career as a photographer and currently works as a lead
            photographer at twogether studios under Arjun Kartha. His personal
            work has been inspired by the idea of a place and its connection
            with the people living within it. His photography diploma project
            titled "Birlanagar" was based on the same idea and was developed as
            a handmade photo book. He worked at Twogetherstudios
            as a Senior Lead Photographer from 2017-2021.
          </p>
        </div>
        <div className="contact-info-container">
          <h1 className="page-heading">Contact Info</h1>
          <div className="contact-info">
            <EmailIcon />
            <a className="about-link" href="mailto:nikhilmishraphotography@gmail.com">
            nikhilmishraphotography@gmail.com
            </a>
          </div>
          <div className="contact-info">
            <InstagramIcon />
            <a
              className="about-link"
              href="https://www.instagram.com/in_no_great_hurry/"
              target="_blank"
              rel="noopener noreferrer"
            >
              in_no_great_hurry
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}
